import React from "react"

// TODO: Turn it on inside config-data.js file
const Animation = () => {

	return(
    <></>
  );
};

export default Animation